<template>
  <div class="alert" :class="boxColor">
    <div v-if="[6,7].includes(statusId)">
      <span>{{ ( eventType === 'followup') ? $t('Follow-up') : $t('Appointment') }} {{ $t('on') }}</span>
      <div class="nk-text-dark small font-weight-bold lh-n">
        {{ eventDate }} <span class="nk-text-muted">{{ $t('at') }}</span> {{ eventTime }}
      </div>
    </div>
    <span v-else>{{ statusText }}</span>
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {
    statusId: Number,
    statusText: String,
    eventType: String,
    eventDate: String,
    eventTime: String,
    filled: undefined,
  },
  computed:{
    boxColor(){
      let classesList = [];
      let colors = [
        {status:1, color: 'warning'},
        {status: 2, color: 'primary'},
        {status: 3, color: 'success'},
        {status: 4, color: 'danger'},
        {status: 5, color: 'danger'},
        {status: 6, color: 'success'},
      ];
      for(let x in colors){
        if(colors[x].status === this.$props.statusId){
          classesList.push(`alert-${colors[x].color}`)
          break;
        }
      }
      if( this.$props.filled !== undefined){
        classesList.push('alert-filled')
      }
      return classesList
    }
  }
}
</script>
