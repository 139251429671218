<template>
  <card class="pb-4">
    <h6 class="mt-0">{{ $t('Status') }}</h6>
    <row>
      <column auto class="flex-fill pr-0">
        <lead-status
            :status-id="lead_data.status_id"
            :status-text="lead_data.status_text"
            :event-type="lead_data.event_type"
            :event-date="lead_data.event_date"
            :event-time="lead_data.event_time"
            class="p-1 font-weight-bold text-center small h-100"></lead-status>
      </column>
      <column sm="3">
        <nk-button is-icon-only-button size="" type="dark" class="h-100" full-width v-on:click="setModalOpenStatus(true)">
          <nio-icon icon="ni-edit"></nio-icon>
        </nk-button>
      </column>
    </row>
    <nk-button type="dark" full-width class="mt-4" v-on:click="[followupSchedule(), setModalOpenStatus(true)]">
      <nio-icon icon="ni-calendar"></nio-icon>
      <span>{{ $t('Follow-up schedule')}}</span>
    </nk-button>
  </card>
  <card>
    <div>
      <div class="small font-weight-bold nk-text-dark">{{ $t('Lead received on') }}:</div>
      <div class="small nk-text-muted">{{ lead_data.received_date }} {{ $t('on') }} {{ lead_data.received_time}}</div>
    </div>
    <div class="mt-3">
      <div class="small font-weight-bold nk-text-dark">{{ $t('Rate this lead') }}</div>
      <div class="small nk-text-muted"></div>
    </div>
  </card>

  <ion-modal
      :is-open="isModalOpenRef"
      css-class="my-custom-class"
      @didDismiss="setModalOpenStatus(false)"
  >
    <i-modal title="Change lead status" @modal-close="setModalOpenStatus(false)">
      <div style="max-width: 350px;" class="m-auto">
        <div class="form-group">
          <label for="lead_new_status">Lead Status</label>
          <ion-select :value="leadStatusChange.new_status_id"
                      id="lead_new_status" ok-text="Okay"
                      cancel-text="Dismiss"
                      interface="action-sheet"
                      v-model="leadStatusChange.new_status_id" class="form-control">
            <ion-select-option v-for="status in lead_all_statuses" :value="status.id" :key="status.id">{{ status.status_text }}</ion-select-option>
          </ion-select>
        </div>
        <div class="form-group" v-if="[6,7].includes(leadStatusChange.new_status_id)"> <!-- 6 = Appointment, 7 = followup -->
          <ion-label>{{ (6 === leadStatusChange.new_status_id) ? $t('Appointment') : $t('Followup') }} {{ $t('date')+' '+$t('time') }}</ion-label>
          <ion-datetime
              display-timezone="Europe/Berlin"
              display-format="DD-MM-YYYY HH:mm"
              picker-format="DD-MM-YYYY HH:mm" class="form-control"
              v-model="leadStatusChange.event_date">

          </ion-datetime>
        </div>
        <div class="mt-3">
          <ion-spinner v-if="status_updating"></ion-spinner>
          <nk-button type="dark" v-on:click="updateLeadStatus" v-else>Update Status</nk-button>
        </div>
      </div>
    </i-modal>
  </ion-modal>

</template>

<script>
import Card from "@core/components/cards/Card";
import {Row, Column } from "@core/layouts"
//import { BSelect} from "@core/components/bp-form"
import LeadStatus from "@/views/components/LeadStatus";

import {defineComponent, ref, watch, toRefs, reactive} from "vue";
import {useStore} from "vuex"
import axios from "@/libs/axios";

import { IonModal, IonDatetime, IonLabel, IonSelect, IonSelectOption, toastController, IonSpinner } from '@ionic/vue';
import IModal from "@core/components/ion-modal/IModal";
import NkButton from "@core/components/button/NkButton";

export default defineComponent({
  components: {
    IonSpinner,
    IonSelect,
    IonSelectOption,
    IonDatetime,
    IonLabel,
    NkButton,
    IModal,
    IonModal,
    Card,
    Column,
    LeadStatus,
    Row,
  },
  props: {
    lead_data: {
      type: Object,
      required: true,
    },
  },
  setup( props, context ){

    const store = useStore()
    const lead_all_statuses = store.state.general_data.leads_statuses

    let status_updating  = ref(false)
    let leadStatusChange = reactive({
      new_status_id: 0,
      event_date: '',
    })

    const {status_id, event_date_iso}  = toRefs(props.lead_data)
    watch(status_id, (newval)=>{
      leadStatusChange.new_status_id = newval
    })
    watch(event_date_iso, (newVal) => {
      leadStatusChange.event_date = newVal
    })

    const isModalOpenRef = ref(false);
    const setModalOpenStatus = (state) => isModalOpenRef.value = state;

    const openToast = async (msg, color) => {
      const toast = await toastController
          .create({
            header: (color === 'success' ? 'Success' : 'Error'),
            message: msg,
            duration: 3800,
            color: color,
            position: 'top',
          })
      return toast.present();
    }

    const updateLeadStatus = () => {
      status_updating.value = true
      axios.post('/api/advisor/lead/change_status', {id: props.lead_data.AL_id, status: leadStatusChange.new_status_id, event_date: leadStatusChange.event_date})
      .then(resp => {
        let lead_data = props.lead_data
        lead_data.status_id = leadStatusChange.new_status_id
        // set lead new status text
        for(let x in lead_all_statuses){
          if(lead_all_statuses[x].id == leadStatusChange.new_status_id){
            lead_data.status_text = lead_all_statuses[x].status_text
            break;
          }
        }
        context.emit('update:lead_data', lead_data)
        if(resp.data.error){
          openToast(resp.data.error, 'danger')
        }else{
          //props.lead_data.status_id = leadStatusChange.new_status_id
          openToast('Thank you! lead status changed.', 'success')
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => status_updating.value = false)
    }
    const followupSchedule = () => leadStatusChange.new_status_id = 7

    return {
      lead_all_statuses,
      isModalOpenRef,
      setModalOpenStatus,
      updateLeadStatus,
      leadStatusChange,
      status_updating,
      followupSchedule,
    }
  }
})
</script>
