<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-content>
            <row class="g-gs">
              <column :xxl="4">
                <div>
                  <user-small-card :user-name="lead_details.first_name+' '+lead_details.last_name">
                    <span>{{ lead_details.post_code }}, {{ lead_details.city}}, {{ lead_details.province }}</span>
                  </user-small-card>
                  <row class="mt-2">
                    <column class="col-auto">
                      <nk-button size="xs" type="success" :is-icon-only-button="true">
                        <nio-icon icon="ni-call"></nio-icon>
                      </nk-button>
                      <badge type="success" :outline="true" :dim="true" :content="lead_details.phone_no"></badge>
                    </column>
                    <column class="col-auto">
                      <nk-button size="xs" type="primary" :is-icon-only-button="true">
                        <nio-icon icon="ni-mail"></nio-icon>
                      </nk-button>
                      <badge type="primary" :outline="true" :dim="true" :content="lead_details.email"></badge>
                    </column>
                  </row>
                </div>
                <lead-data :lead_data="lead_details"></lead-data>
              </column>

              <column :xxl="5">
                <card :full-height="true" no-padding="true" id="home_notifications_card">
                  <div class="p-4">
                    <row class="nk-text-dark" align-v="center">
                      <column :md="5">
                        <div class="font-weight-bold">{{ $t('Your location') }}</div>
                        <div class="nk-text-muted lh-n">{{ advisorData.city }}, {{advisorData.province}}<br/> {{advisorData.post_code}}</div>
                      </column>
                      <column :md="2" class="text-center">
                        <nio-icon icon="ni-arrow-right" style="font-size: 30px;"></nio-icon>
                        <div v-if="distance > 0">
                          <badge :content="'&#177; '+distance+' KM'" pill type="success"></badge>
                        </div>
                      </column>
                      <column :md="5" class="text-right">
                        <div class="font-weight-bold">{{ lead_details.city }}</div>
                        <div class="nk-text-muted lh-n">{{ lead_details.house_number }},<br/> {{ lead_details.post_code }}</div>
                      </column>
                    </row>
                  </div>
                  <lead-location-map
                      map-type="roadmap"
                      :start="advisorData.post_code+','+advisorData.city"
                      :end="lead_details.post_code+','+lead_details.city"
                      @distance-changed="updateDistance"></lead-location-map>
                </card>
              </column>

              <column :xxl="3">
                <lead-status-and-rating v-model:lead_data="lead_details"></lead-status-and-rating>
              </column>

              <column :md="8">
                <accordion id="lead_notes" class="is-separate bg-transparent">
                  <accordion-item v-for="(note, index) in lead_details.notes" :key="note.id" class="light-shadow">
                    <accordion-item-head :data-target="'#note-'+index" :collapsed="index > 0">
                      <div class="d-md-flex">
                        <div class="flex-fill">{{ $t('Note')+' #'+(index+1) }}</div>
                        <div class="pr-md-5">
                          <span class="font-weight-normal">{{ $t('on') }}: </span>
                          {{ note.added_date }}, <span class="font-weight-normal">{{ $t('at')}}</span> {{ note.added_time }}
                        </div>
                      </div>
                    </accordion-item-head>
                    <accordion-item-body :id="'note-'+index" data-parent="#lead_notes" :collapsed="index > 0" v-html="note.note" class="p-3">

                    </accordion-item-body>
                  </accordion-item>
                </accordion>
              </column>

              <column :md="4">
                <card no-padding>
                  <card-header class="bg-dark radius-top-5 p-3 text-white" :title="$t('New note')"></card-header>
                  <div class="p-4">
                    <ckeditor class="border-light border" :editor="InlineEditor" v-model="new_note" :config="editorConfig"></ckeditor>
                    <div class="mt-2 text-right">
                      <ion-spinner v-if="noteAddingSpinner"></ion-spinner>
                      <nk-button v-else type="dark" v-on:click="add_new_note">{{ $t('Save note') }}</nk-button>
                    </div>
                  </div>
                </card>
              </column>
            </row>
          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, toastController, IonSpinner } from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, /*nextTick*/} from 'vue';
//import axios from "@/libs/axios";
//import card from "@/layouts/components/card";
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import { Row, Column } from '@core/layouts';
import { Card, CardHeader } from '@core/components/cards'
import axios from "@/libs/axios";

import "bootstrap"
//import $ from "jquery"
import { useRoute } from "vue-router"
import LeadData from "@/views/lead-details/LeadData";
import LeadStatusAndRating from "@/views/lead-details/LeadStatusAndRating";
import Accordion from "@core/components/accordion/Accordion";
import AccordionItem from "@core/components/accordion/components/AccordionItem";
import AccordionItemHead from "@core/components/accordion/components/AccordionItemHead";
import AccordionItemBody from "@core/components/accordion/components/AccordionItemBody";

import CKEditor from "@ckeditor/ckeditor5-vue"
import InlineEditor from "@ckeditor/ckeditor5-build-inline"
import NkButton from "@core/components/button/NkButton";
import NioIcon from "@core/components/nio-icon/NioIcon";
import Badge from "@core/components/badge/Badge";
import { useI18n } from "vue-i18n"
import LeadLocationMap from "@/views/lead-details/LeadLocationMap";
import { useStore } from "vuex"
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";

export default defineComponent({
  name: 'Home',
  components: {
    LeadLocationMap,
    Badge,
    NioIcon,
    NkButton,
    AccordionItemBody,
    AccordionItemHead,
    AccordionItem,
    Accordion,
    LeadStatusAndRating,
    LeadData,
    ckeditor: CKEditor.component,
    CardHeader,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonSpinner,
    UserSmallCard,
    /*IonHeader,
    IonTitle,
    IonToolbar,*/
  },
  setup() {

    const store  = useStore()

    const router = useRoute()

    let new_note        = ref('')
    let lead_details    = reactive(
        {
          status_id: 0,
          event_date_iso: '',
          event_date: '',
          first_name: '',
          last_name: '',
          notes: [],
          city: '',
          post_code: '',
        }
    )

    const advisorData = store.state.advisor_data

    const getLeadDetails = async () => {
      axios.post('/api/advisor/lead/detail', {id: router.params.id})
          .then( (resp) => {
            if(resp.data.error){
              console.log('Lead details error: '+resp.data.error);
            }
            else{
              for(let x in resp.data.data){
                lead_details[x] = resp.data.data[x];
              }
            }
          })
          .catch( err => {
            console.log('Lead details ajax: '+err)
          })
    }
    onMounted(getLeadDetails)

    const editorConfig = {
      removePlugins: ['Heading'],
      toolbar: [ 'bold', 'italic', /*'underline',*/ 'link', /*'alignment'*/ ],
    }

    const { t } = useI18n()
    let noteAddingSpinner = ref(false)
    const add_new_note = async () => {
      if(new_note.value.length < 1){
        return false;
      }
      noteAddingSpinner.value = true
      axios.post('/api/advisor/lead/add_note', {id: router.params.id, note: new_note.value})
          .then( (resp) => {
            if(resp.data.error){
              toastController.create({color: 'danger', message: resp.data.error, duration:3000}).then( toast => toast.present())
              console.log('Note adding server error: '+resp.data.error)
            }
            else{
              new_note.value = ''
              lead_details.notes.unshift(resp.data)
              toastController.create({color: 'success', message: t('Thank you! note added'), duration:2000}).then( toast => toast.present())
            }
          })
          .catch(err => {
            toastController.create({color: 'warning', message: 'An error occurred. Please check your internet connection.', duration:3000}).then( toast => toast.present())
            console.log('Note adding ajax error: '+err)
          })
      .then(() => noteAddingSpinner.value = false)
    }

    let distance = ref(0)
    const updateDistance = (d) => {
      distance.value = d
    }

    return {
      advisorData,
      lead_details,
      InlineEditor,
      new_note,
      editorConfig,
      add_new_note,
      noteAddingSpinner,
      distance,
      updateDistance,
    }
  }
});
</script>
